import React from 'react';
import { graphql, Link } from 'gatsby';
import {
	ButtonContainer,
	MessageDiv,
	StyledArticle,
	StyledBottomLinks,
	StyledButton,
	StyledSuperArticle,
} from '../styles';

import { SEO } from '../components/SEO';
import { Layout } from '../components/Layout';
import { StyledH2 } from '../styles/common';

function IndexPage({ data }: any) {
	const { allMarkdownRemark } = data;
	const { edges } = allMarkdownRemark;

	return (
		<Layout>
			{edges.map(({ node }: any) => {
				const { id, html, frontmatter } = node;
				const { title, color, button, slug, highlighted } = frontmatter;
				return highlighted ? (
					<StyledSuperArticle color={color} key={id}>
						{title ? <StyledH2 color={color}>{title}</StyledH2> : null}
						<MessageDiv dangerouslySetInnerHTML={{ __html: html }} />
						{button !== '' ? (
							<ButtonContainer>
								<StyledButton as="a" href={slug}>
									{button}
								</StyledButton>
							</ButtonContainer>
						) : null}
					</StyledSuperArticle>
				) : (
					<StyledArticle color={color} key={id}>
						{title ? <StyledH2 color={color}>{title}</StyledH2> : null}
						<MessageDiv dangerouslySetInnerHTML={{ __html: html }} />
						{button !== '' ? (
							<ButtonContainer>
								<StyledButton as="a" href={slug}>
									{button}
								</StyledButton>
							</ButtonContainer>
						) : null}
					</StyledArticle>
				);
			})}

			<StyledBottomLinks>
				<Link to="/2" rel="next">
					Następna strona →
				</Link>
			</StyledBottomLinks>
		</Layout>
	);
}

export default IndexPage;

export function Head() {
	return (
		<SEO
			title="Aktualności"
			description="Aktualne informacje ze Spółki MZK Bydgoszcz."
		/>
	);
}

export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { frontmatter: { menu: { eq: "aktualnosci" } } }
			sort: { fields: frontmatter___position, order: DESC }
			limit: 5
		) {
			edges {
				node {
					id
					html
					frontmatter {
						slug
						title
						color
						button
						highlighted
					}
				}
			}
		}
	}
`;
